<template>
  <div>
    <div class="card mb-3 border-0 bg-light shadow-none">
      <div class="card-header bg-light">
        <div class="row">
          <div class="col my-auto">
            <button
              type="button"
              class="btn btn-sm btn-outline-primary"
              @click="display = true"
            >
              <i class="far fa-check me-2"></i>View / Edit Template
            </button>
          </div>
          <div class="col-auto ms-auto my-auto">
            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              @click="deleteTimeline"
            >
              <i class="far fa-trash"></i>
            </button>
          </div>

          <div class="col-auto my-auto">
            <button v-if="display"
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click="display = false"
            >
              <i class="far fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" @click="display = true">
        <h5 class="mb-2">
          <span v-if="editTitle === false">
                      {{ timeline.title }}
                      <small @click="editTitle = true"><i class="fas fa-edit cursor-pointer"></i></small>
                    </span>
                    <span v-else>
                      <div class="form-inline">
                      <input type="text" v-model="timeline.title" class="form-control form-control-sm w-25 me-2" />
                      <small @click="updateTimeline()"><i class="fas fa-save cursor-pointer"></i></small>
                      </div>
                    </span>
        </h5>
        <div v-if="display">
          <section-part
            class="mb-2"
            v-for="h in timeline.sections"
            :key="h.id"
            :section="h"
            :timeline="timeline"
          ></section-part>
          <form @submit.prevent="createSection">
            <div class="row mt-3">
              <div class="col my-auto">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add a section to this timeline (press enter to save)"
                  v-model="section"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Section from "./Section";

export default {
  props: ["timeline"],
  data() {
    return {
      section: "",
      display: false,
      editTitle: false,
    };
  },
  methods: {
    updateTimeline() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/timelines/api/" + this.timeline.id,
          {
            title: this.timeline.title,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.editTitle = false;
        });
    },
    createSection() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/timelines/api/create-section",
          {
            section: this.section,
            clinic_timeline_id: this.timeline.id,
          }
        )
        .then(({ data }) => {
          this.section = "";
          if (this.timeline.sections) {
            this.timeline.sections.push(data.section);
          } else {
            this.timeline.sections = [];
            this.timeline.sections.push(data.section);
          }
          this.$EventBus.$emit("alert", data);
        });
    },
    deleteTimeline() {
      const confRes = confirm(
        "Are you sure you wish to delete this and all associated data? This cannot be undone!"
      );
      if (!confRes) {
        return;
      }

      this.$axios
        .delete(
          process.env.VUE_APP_API_URL +
            "/settings/timelines/api/" +
            this.timeline.id
        )
        .then(({ data }) => {
          let obj = this.$parent.timelines.find((o) => o.id == this.timeline.id);
          var index = this.$parent.timelines.indexOf(obj);
          this.$parent.timelines.splice(index, 1);
          this.$EventBus.$emit("alert", data);
        });
    },
  },
  mounted() {},
  components: {
    "section-part": Section,
  },
};
</script>

<style>
</style>