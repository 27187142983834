<template>
  <div>
    <li class="list-group-item sub-headings">
      <div class="row">
        <div class="col my-auto">
          <h6 v-if="!editSubsection" class="mb-0">{{ subSection.title }}</h6>
          <input
            v-else
            type="text"
            class="form-control"
            v-model="subSection.title"
            @change="updateSubsection"
          />
        </div>
        <div class="col-auto ms-auto my-auto">
          <button
            type="button"
            class="btn btn-xs btn-light"
            @click="editSubsection = true"
          >
            <i class="far fa-edit"></i>
          </button>
          <button
            type="button"
            class="btn btn-xs btn-light"
            @click="deleteSection"
          >
            <i class="far fa-trash"></i>
          </button>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  props: ["subSection", "timeline"],
  data() {
    return {
      editSubsection: false,
    };
  },
  methods: {
    deleteSection() {
      if (!confirm("Are you sure you wish to remove this section?")) {
        return;
      }
      this.$axios
        .delete(
          process.env.VUE_APP_API_URL +
            "/settings/timelines/api/sections/" +
            this.subSection.id
        )
        .then(({ data }) => {
          let obj = this.$parent.section.sub_sections.find((o) => o.id == this.subSection.id);
          var index = this.$parent.section.sub_sections.indexOf(obj);
          this.$nextTick(() => {
            this.$parent.section.sub_sections.splice(index, 1);
          });
          this.$EventBus.$emit("alert", data);
        });
    },
    updateSubsection() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/timelines/api/update-section",
          {
            clinic_timeline_section_id: this.subSection.id,
            title: this.subSection.title,
          }
        )
        .then(({ data }) => {
          this.editSubsection = false;
          this.$EventBus.$emit("alert", data);
        });
    },
  },
};
</script>

<style>
</style>