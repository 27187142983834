<template>
  <div>
    <li class="list-group-item">
      <div class="row mb-3">
        <div class="col my-auto">
          <h5 v-if="!editSection" class="mb-0">{{ section.title }}</h5>
          <input
            v-else
            type="text"
            class="form-control"
            v-model="section.title"
            @change="updateSection"
          />
        </div>
        <div class="col-auto ms-auto my-auto">
          <button
            type="button"
            class="btn btn-xs btn-light"
            @click="editSection = true"
          >
            <i class="far fa-edit"></i>
          </button>
          <button
            type="button"
            class="btn btn-xs btn-light"
            @click="deleteSection"
          >
            <i class="far fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="card border-0 bg-light shadow-none">
        <div class="card-body">
          <!--  -->
          <ul class="list-group-flush sections">
            <sub-section
              v-for="sh in section.sub_sections"
              :key="sh.id"
              :sub-section="sh"
              :timeline="timeline"
            ></sub-section>
          </ul>

          <form @submit.prevent="createSection">
            <div class="row mt-3">
              <div class="col my-auto">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Add a sub section (press enter to save)"
                  v-model="subSection"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import SubSection from "./SubSection";

export default {
  props: ["section", "timeline"],
  data() {
    return {
      subSection: "",
      editSection: false,
    };
  },
  methods: {
    createSection() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/timelines/api/create-section",
          {
            section: this.subSection,
            clinic_timeline_id: this.timeline.id,
            clinic_timeline_section_id: this.section.id,
          }
        )
        .then(({ data }) => {
          this.subSection = "";
          if (this.section.sub_sections) {
            this.section.sub_sections.push(data.section);
          } else {
            this.section.sub_sections = [];
            this.section.sub_sections.push(data.section);
          }
          this.$EventBus.$emit("alert", data);
        });
    },
    deleteSection() {
      if (!confirm("Are you sure you wish to remove this section?")) {
        return;
      }
      this.$axios
        .delete(
          process.env.VUE_APP_API_URL +
            "/settings/timelines/api/sections/" +
            this.section.id
        )
        .then(({ data }) => {
          let obj = this.timeline.sections.find((o) => o.id == this.section.id);
          var index = this.timeline.sections.indexOf(obj);
          this.timeline.sections.splice(index, 1);
          this.$EventBus.$emit("alert", data);
        });
    },
    updateSection() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/timelines/api/update-section",
          {
            clinic_timeline_section_id: this.section.id,
            title: this.section.title,
          }
        )
        .then(({ data }) => {
          this.editSection = false;
          this.$EventBus.$emit("alert", data);
        });
    },
  },
  components: {
    SubSection,
  },
};
</script>

<style>
</style>