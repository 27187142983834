<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Tab cards -->
      <h4 class="fw-bold text-primary">Timeline Setup</h4>
      <div class="col">
        <!--  -->
        <div class="row mb-3">
          <div class="col">
            <p class="alert alert-primary">
              <i class="far fa-info me-2"></i>
              Create client timelines, a timeline has sections, a section
              can be thought of like a question or a statement, sections can
              have sub-sections. Each section can then have as many items
              added to it as you wish from the client profile, each item
              that is added is then timestamped to enable a timeline view of
              a clients history. Once your sections are ready in a timeline
              template, visit a client profile to create their timeline!
            </p>
            <h5>Example sections with sub-sections</h5>
            <ul class="ms-3 mt-0">
              <li class="ms-4">General Wellbeing</li>
              <ul class="ms-3 mt-0">
                <li class="ms-4">Physical</li>
                <li class="ms-4">Emotional</li>
              </ul>
              <li class="ms-4">Diet</li>
              <ul class="ms-3 mt-0">
                <li class="ms-4">Healthy foods consumed</li>
                <li class="ms-4">Supplements taken</li>
              </ul>
            </ul>
          </div>
        </div>

        <!--  -->
        <div class="card border-0 bg-light mb-3">
          <div class="card-body">
            <form @submit.prevent="createTimeline">
              <div class="row">
                <div class="col my-auto">
                  <input
                    type="text"
                    v-model="timelineTitle"
                    class="form-control"
                    placeholder="Timeline template title"
                    required
                  />
                </div>
                <div class="col-auto ms-auto my-auto">
                  <button class="btn btn-outline-primary">
                    <i class="far fa-plus me-2"></i>Create Timeline Template
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!--  -->
        <timeline
          class="mb-3"
          v-for="t in timelines"
          :key="t.id"
          :timeline="t"
        ></timeline>
      </div>
    </div>
  </div>
</template>

<script>

import Timeline from "./timeline-partials/Timeline";

export default {
  props: [],
  data() {
    return {
      timelines: [],
      timelineTitle: "",
    };
  },

  methods: {
    createTimeline() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/settings/timelines/api", {
          clinic_id: this.user.clinic.id,
          title: this.timelineTitle,
        })
        .then(({ data }) => {
          this.timelineTitle = "";
          this.timelines.push(data.timeline);
          this.$EventBus.$emit("alert", data);
        });
    },
    fetchTimelines() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/settings/timelines/api")
        .then(({ data }) => {
          this.timelines = data;
        });
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    }
  },
  mounted() {
    this.fetchTimelines();
  },
  components: {

    Timeline,
  },
};
</script>



<style>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>